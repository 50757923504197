import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withOrderContext from '../../../withOrderContext';
import { margins } from '../../styledComponents';
import {
  colors,
  mobileThresholdPixels,
} from '../../home/v3/styledComponents';
import { Subtitle } from '../orderStyledComponents';

import iconBasket from '../../../assets/order/iconBasket.svg';

const Container = styled.div`
  width: ${({ vertical }) => vertical ? 345 : 946}px;
  box-sizing: border-box;
  height: auto;
  margin: ${({ vertical }) => vertical ? '0px' : '80px auto 0px'};
  background-color: ${colors.white};
  padding: ${({ vertical }) => vertical ? '16px 30px 2px' : `${margins.l} ${margins.l} ${margins.s} ${margins.l}`};
  border-top: 8px solid ${colors.blueGrey};
  text-align: left;
  color: ${colors.navy};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
    padding: 20px;
    margin: 45px auto;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  line-height: 24px;
  font-size: 18px;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical ? 'column' : 'row'};
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  width: ${({ vertical }) => vertical ? 'unset' : '369px'};

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
  }
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ grey }) => grey && `color: ${colors.lightGrey3}; `}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const Line = styled.div`
  border-width: 0.5px;
  border-style: solid;
  border-color: ${colors.lightGrey2};
  margin: ${props => props.marginTop || 20}px 0px ${props => props.noMarginBottom ? 0 : 20}px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${props => props.marginTop || 10}px 0px 10px;
  }
`;

const OrderSummaryMasks = (props) => {
  const {
    deliveryFee, masks,
  } = props;

  const totalMasks = masks.reduce((total, mask) => total + (mask.quantity * mask.price), 0);
  const totalPaid = totalMasks + deliveryFee;

  return (
    <Container vertical>
      <StyledSubtitle left>
        <img src={iconBasket} alt="Panier" />{' Votre panier'}
      </StyledSubtitle>
      {<Line marginTop={14} noMarginBottom />}
      <OrderContainer vertical>
        <SubContainer vertical>
          {masks.map(mask => (
            <TotalRow key={mask.name}>{mask.name} ({mask.quantity}x) <span>{mask.quantity * mask.price} €</span></TotalRow>
          ))}
          <TotalRow>Frais de livraison <span>{deliveryFee} €</span></TotalRow>
          <Line />
          <TotalRow>
            Total <span>{totalPaid} €</span>
          </TotalRow>
        </SubContainer>
      </OrderContainer>
    </Container>
  );
};

OrderSummaryMasks.propTypes = {
  masks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deliveryFee: PropTypes.number.isRequired,
};

export default withOrderContext(OrderSummaryMasks);
