import React from 'react';

import Layout from '../../layouts/index';
import { Container, Content, StepContainer } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import MeetingMaskInfos from '../../components/order/MeetingMaskInfos';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';

const MasksFormPage = () => (
  <Layout routeSlug="MasksForm">
    <Container hasNoBanner>
      <MenuBar showGiftBanner={false} showBannerMask={false} />
      <Content>
        <StepContainer smallMarginBottom>
          <MeetingMaskInfos />
        </StepContainer>
      </Content>
      <BenefitsBanner backgroundColor="linen" color="navy" />
      <Footer />
    </Container>
  </Layout>
);

export default MasksFormPage;
