import React from 'react';
import styled from 'styled-components';
import Dots from '../home/v3/Dots';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';

const Container = styled.div`
  position: fixed;
  background-color: ${colors.opacityFilter};
  width: 100vw;
  height: 100vw;
  left: 0;
  top: 0;
  z-index: 10;
`;

// TODO refactor
const PopUpContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  margin: 0 auto;
  display: ${props => props.isDisplayed ? 'flex' : 'none'};
  align-items: center;
  z-index: 2;
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    padding: 18px 22px;
  }
`;

const PopUp = styled(PopUpContainer)`
  background-color: ${colors.white};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  width: 307px;
  padding: 54px 77px;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 50px);
    padding: 54px 25px;
  }
`;

const PopUpText = styled.p`
  margin-top: ${props => props.marginTop ?? 0}px;
  margin-bottom: 5px;
  ${props => props.pointer && 'cursor: pointer;'}
`;

const PopUpWaitTime = () => (
  <Container>
    <PopUp isDisplayed>
      <Dots size={9} spacing={22} isAnimated />
      <PopUpText marginTop={25}>Création de la commande en cours</PopUpText>
      <PopUpText>Merci de patienter quelques instants</PopUpText>
    </PopUp>
  </Container>
);

export default PopUpWaitTime;
